const initialState = {
  items: [],
  totalItems: 0,
  params: {}
}

const reducer = (state = initialState, action) => {
  
  switch (action.type) {
    case 'SET_STOCK_EXTRACTION_LIST': 
      return {
        ...state,
        items: action.items,
        totalItems: action.totalItems,
        params: action.params
      }
    default:
      return state
  }
}

export default reducer
