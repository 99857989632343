const initialState = {
    user: null,
    countriesObj: {},
    countries: [],
    companies: [],
    items: [],
    totalItems: 0,
    params: {}
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_USERS':
            return {
                ...state,
                items: action.items,
                totalItems: action.totalItems,
                params: action.params
            }
        case 'SET_COUNTRIES':
            return {
                ...state,
                countries: action.countries,
                countriesObj: action.countriesObj
            }
        case 'SET_COMPANIES':
            return {
                ...state,
                companies: action.companies
            }
        case 'SET_USER':
            return {
                ...state,
                user: action.user
            }
        case 'ADD_USER':
            return {...state}
        case 'DELETE_USER':
            return {...state}
        default:
            return state
    }
}

export default reducer
